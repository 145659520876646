const initialState = {
    showModal: false
}

const drawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_SHOW_MODAL": {
            return {
                ...state,
                showModal: action.payload
            }
        }
        default:
            return state;
    }
}

export default drawerReducer;