// ** Initial State

const initialState = {
    filters: {
        rowsPerPage: 10,
        page: 1,
        dateRange: [],
        url: '',
        dealers: [],
        customer_id: null,
        teams_id: ''
    },
    selectedFilterVal: {
        dealerships: [{ value: "", label: "All Dealerships" }],
        teams: [{ value: "", label: "All Teams" }]
    },
    records: [],
    rowsPerPage: 10,
    totalRecords: 0,
    showDrawer: false,
    showSchedular: false,
    showTeamAgent: false,
    schedulerData: [],
    drawerMode: false,
    drawerEditRow: false,
    loading: false,
    dropdownItems: false,
    selectedValue: [],
    dropdownUrl: false,
    dealerships_users: 'rw',
    theme_change: false,
    dealers: [],
    tools: [],
    roles: [],
    filteredRecords: [],
    customer_id: null,
    customers: [],
    teams: []
}

const datatableReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DEALERSHIPS_USERS': {
            return {
                ...state,
                dealerships_users: action.payload,
            }
        }
        case 'SET_FILTERS':
            return {
                ...state,
                filters: action.payload,
            }
        case 'SET_FILTER_VAL':
            return { ...state, selectedFilterVal: action.payload }

        /* case 'SET_THEME_CHANGE':
            return {
                ...state,
                theme_change: action.payload,
            }
            */

        case 'SET_RECORDS':
            return {
                ...state,
                records: action.payload['data'],
                totalRecords: action.payload['total'],
                // loading: false
            }
        case 'SET_DEALER_RECORDS': {
            return {
                ...state,
                filteredRecords: action.payload['data'],

            }
        }
        case 'SET_DEALER':
            return {
                ...state,
                dealers: action.payload,
            }
        case 'SET_TOOL':
            return {
                ...state,
                tools: action.payload,
            }
        case 'SET_ROLE':
            return {
                ...state,
                roles: action.payload,
            }
        case 'SET_DEALERSHIP_FILTER':
            return {
                ...state,
                customer_id: action.payload,
            }
        case 'SET_TEAM_FILTER':
            return {
                ...state,
                teams_id: action.payload,
            }
        case 'SET_DEALERSHIP_RECORD':
            return {
                ...state,
                customers: action.payload,
            }

        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload,
            }
        case 'SET_SIDEBAR_LOADING':
            return {
                ...state,
                sidebarLoading: action.payload,
            }
        case 'SHOW_DRAWER':
            return {
                ...state,
                showDrawer: action.payload,
            }
        case 'SHOW_SCHEDULER':
            return {
                ...state,
                showScheduler: action.payload,
            }
        case 'SHOW_TEAM_AGENT':
            return {
                ...state,
                showTeamAgent: action.payload,
            }
        case 'SET_TEAMS':
            return {
                ...state,
                teams: action.payload,
            }
        case 'SCHEDULER_DATA':
            return {
                ...state,
                schedulerData: action.payload,
            }
        case 'TRIGGER_VALIDATION':
            return {
                ...state,
                validation: action.payload,
            }
        case 'SET_DRAWER_EDIT_ROW':
            return {
                ...state,
                drawerEditRow: action.payload,
            }

        case 'SET_DROPDOWN_DATA':
            return {
                ...state,
                dropdownItems: action.payload,
            }
        case 'SET_DROPDOWN_URL':
            return {
                ...state,
                dropdownUrl: action.payload,
            }
        case 'ALLOWANCE_CRITERIA':
            return {
                ...state,
                records: action.payload,
            }
        case 'INITIATE':
            return initialState


        default:
            return state
    }
}
export default datatableReducer;