
import logo from "@src/assets/images/logo/SMARTER (Transparent).png"

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader' style={{ backgroundColor: "white" }}>
      <img className='fallback-logo main-logo' src={logo} alt='logo' />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}


export default SpinnerComponent
