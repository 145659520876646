const initialState = {
    customer: false,
    loadingCustomer: false,
    logo: false,
    dealerships: [],
}

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CUSTOMER":
            return {
                ...state,
                customer: action.payload
            }
        case "SET_CUSTOMER_LOADING":
            return {
                ...state,
                loadingCustomer: action.payload
            }

        case "SET_LOGO":
            return {
                ...state,
                logo: action.payload
            }
        case "SET_BLOCK_EMAIL_OR_IP": {
            return {
                ...state,
                logo: action.payload
            }
        }
        case "SET_DEALERSHIP": {
            return {
                ...state,
                dealerships: action.payload
            }
        }
        default:
            return state
    }
}
export default customerReducer;